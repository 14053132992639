<template>
  <EntityList
    :config="config"
    :columns="columns"
    :formatted-columns="formattedColumns"
    :filters="filters"
    :filter-vals.sync="filterVals"
    :form-fields="formFields"
    :single-edit="isAdmin"
    :allow-duplicate="false"
    :custom-query="customQuery"
    authority="Users_Payment"
    details-view     
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'

import { getSubscriptionTypes, isAdmin, getCompanyId } from '@/auth/utils'

import storeModule from '../dealSubscriptionPaymentsStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      paymentMethodOptions: [],
      companyOptions: [],
      subscriptionOptions: [],
    }
  },
  computed: {
    isAdmin() {
      return isAdmin()
    },
    companyId() {
      return getCompanyId()
    },
    config() {
      return {
        store: storeModule,
        module: 'payments/invoices',
        endpoint: 'payments/invoices',
        route: 'deal-subscription-payments',
        title: {
          single: this.$i18n.t('Payment'),
          plural: this.$i18n.t('Payments'),
        },
      }
    },
    customQuery() {
        if (this.isAdmin) {
          return({category: "DEAL", company: "", sort: "id,desc"})
        } else {
          return({category: "DEAL", company: this.companyId, sort: "id,desc"})
        }     
      },

    subscriptionTypeOptions() {
      return getSubscriptionTypes()
    },

    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'company.title', label: this.$t('Company'), sortable: true },
        { key: 'userGroup.title', label: this.$t('Subscription'), sortable: true },
        { key: 'amount', label: this.$t('Amount'), sortable: true },
        // { key: 'paymentMethod.title', label: this.$t('Payment Method'), sortable: false },
        { key: 'paymentId', label: this.$t('Transaction ID'), sortable: false },
        { key: 'creationDate', label: this.$t('Payment Date'), sortable: true },
        { key: 'status', label: this.$t('Status') },
        { key: 'actions', label: this.$t('actions') },
      ]
    },

    formattedColumns() {
      return [
      { key: 'amount', type: 'price' },  
      { key: 'creationDate', type: 'datetime' },
      { key: 'status', type: 'translate' },
      ]
    },
    formFields() {
      return [
        {
          id: 'companyId',
          type: 'select',
          label: this.$t('Company'),
          options: this.companyOptions,
          required: true,
        },
        {
          id: 'subscriptionPackage',
          object: true,
          type: 'select',
          label: this.$t('Subscription'),
          options: this.subscriptionOptions,
          required: true,
        },
        {
          id: 'subscriptionType',
          type: 'select',
          label: this.$t('Subscription Type'),
          options: this.subscriptionTypeOptions,
          required: true,
        },
        {
          id: 'amount',
          type: 'text',
          label: this.$t('Paid Amount'),
          required: true,
          rules: 'positive',
        },
        {
          id: 'paymentMethod',
          object: true,
          type: 'select',
          label: this.$t('Payment Method'),
          options: this.paymentMethodOptions,
          required: true,
        },
        {
          id: 'paymentDate',
          type: 'datetime',
          label: this.$t('Payment Date'),
          required: true,
          maxDate: new Date(),
        },
        {
          id: 'subscriptionEndDate',
          type: 'datetime',
          label: this.$t('Subscription End Date'),
          required: false,
          maxDate: new Date(),
        },
        {
          id: 'title',
          type: 'text',
          label: this.$t('title'),
          required: false,
        },
        {
          id: 'comments',
          type: 'quill',
          label: this.$t('comments'),
          required: false,
        },
      ]
    },

    filters() {
      return [
        {
          name: 'createdfrom',
          type: 'date',
          label: this.$i18n.t('Created From'),
          value: null,
          maxDateFieldIdx: '1',
          maxDate: new Date(),
        },
        {
          name: 'createdto',
          type: 'date',
          label: this.$i18n.t('Created To'),
          value: null,
          minDateFieldIdx: '0',
          maxDate: new Date(),
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchPaymentMethods').then(response => {
      this.paymentMethodOptions = response.data
    })
    this.$store.dispatch('app-common/fetchCompanies').then(response => {
      this.companyOptions = response.data
    })
    this.$store.dispatch('app-common/fetchPlainSubscriptions').then(response => {
      this.subscriptionOptions = response.data
    })
  },
}
</script>
